export const HOME = "/";
export const SERVICES = "/services";
export const CONSULATION = "/book-a-call";
export const BLOG = "/blog";
export const CONTACT = "/contact";
export const WORK = "/work";
export const PORTFOLIO = "/portfolio";
export const PORTFOLIO_DETAILS = "/portfolio/:id";
export const HELP_FAQ = "/help-faq";
export const CV = "/cv";
export const TEAM_PROJECTS = "/team/projects";
export const PROJECTS = "/cv/digitze/projects";
export const LOADER = "/loading";
export const TESTIMONIAL = "/testimonial-form";
